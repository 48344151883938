
.gradient-mask {
  -webkit-mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.2)
  );
  mask: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
}

/* .font-itc-franklin-gothic-lt {
  font-family: "ITC Franklin Gothic LT", Arial, sans-serif;
}

.font-bolder {
  font-weight: bolder;
} */
