body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
footer {
  padding: 20px;
}

a {
  color: #00aaff;
  text-decoration: none;
}

/* Page Specific Styles
.home,
.contact-us,
.services,
.careers,
.about-us {
  padding: 50px 0;
  text-align: center;
}

.home h1,
.contact-us h1,
.services h1,
.careers h1,
.about-us h1 {
  color: #00aaff;
  margin-bottom: 20px;
}

.home p,
.contact-us p,
.services p,
.careers p,
.about-us p {
  color: #555;
} */
