@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ITC Gothic Regular";
  src: url("./assets/fonts/ITC\ Franklin\ Gothic\ LT\ Book\ Regular.otf"),
    url("./assets//fonts/ITC\ Franklin\ Gothic\ LT\ Medium.otf");
}
@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/HelveticaNeueMedium.otf"),
    url("./assets//fonts/HelveticaNeueBold.otf"),
    url("./assets//fonts/HelveticaNeueLight.otf");
}

p {
  font-family: "ITC Gothic Regular";
}

h1,h2,h3,h4 #links {
    font-family: 'Helvetica';
}

#links {
    font-weight: 600;
}
